const labs = {
  Lab: "Lab",
  Labs: "Labs",
  LabsDescription: "View staff access to labs and assign a signing practitioner.",
  LabsDescriptionNew:
    "View staff access to labs, assign a signing practitioner, and set a default interpretation fee.",
  JourneyDisclaimer:
    '"Reference ranges" included with testing results are provided by the respective laboratory. "Optimal ranges" are established by various experts based on their own experience and research, including Fullscript\'s Medical Team; they are intended solely as informational reference content. Optimal ranges are not medical diagnoses or treatments, are not a substitute for a practitioner\'s professional judgment in specific individual situations, and are not meant to provide medical or professional advice. While content has been obtained from sources believed to be reliable, we cannot and do not guarantee the accuracy, validity, timeliness or completeness of the content.',
  RecommendationDeletedSuccessfully: "Deleted successfully!",
  LabRecommendationHeader: "Lab – {{date}}",
  StartLab: "Start lab",
  NPI: "NPI",
  BrowserCantOpenPdf:
    "The PDF could not be loaded in this browser. Please try again with a different browser.",
  NPIExplained: "National Provider Identifier (NPI)",
  NPINumberOptional: "NPI number (optional)",
  NPINumber: "NPI number",
  learnMoreAuthNetwork: "Learn more about the authorization network",
  LicenseInformation: "License information",
  WeRequireYourNpi:
    "If you have an NPI number, we'll determine which lab tests you can {{treatmentPlanTerm}} without using an authorization network.",
  DoYouHaveNPI: "Do you have an NPI number?",
  InvalidNpi: "Invalid NPI. Either leave empty or enter a valid NPI number",
  InvalidLicenseInformation:
    "Invalid or incomplete license information entered. Either leave empty or enter all required information for the license in order to proceed without using the authorization network",
  FirstNameRequired: "First name is required",
  LastNameRequired: "Last name is required",
  NPINumberRequired: "NPI number is required",
  CannotContinueWithFilledNpi: "Please enter your name or clear the NPI number",
  PleaseCompleteLabsSetup: "Please complete labs setup",
  NPISubtitle:
    "Please provide us with your NPI number so we can verify that you are authorized to recommend labs.",
  ProfessionalCertificate: "Credentials",
  CredentialsSubtitle:
    "Please provide us with your credentials so lab companies can verify that you are authorized to recommend labs.",
  SelectCredentialsTitle: "Select from one of your existing credentials or upload a new one.",
  UploadNew: "Upload new",
  AcceptedFormats: "Acceptable file formats are  JPG, JPEG, PNG or PDF",
  ProblemAuthenticating:
    "We ran into a problem authenticating you. Please close this window and try  again",
  AuthenticatingDoneTitle: "Authentication complete",
  InvalidAuthenticationCode: "Invalid authentication code",
  AuthenticatingDoneCloseWindow: "You may now close this window.",
  CloseWindow: "Close window",
  NoInOfficeLabs: "Remove labs from the plan to enable in-office checkout",
  CannotUpdateInOfficeKitPlan: "Plans with in-office lab kits cannot be updated",
  CannotCreateTemplatesWithLabs: "Plans with labs cannot be saved as templates",
  SelectStateLicense: "Select the state where your license was issued in.",
  State: "State",
  LabsSettings: "Lab settings",
  LabsSettingsAccess: "Manage your settings for labs access.",
  LabOrder: "Lab recommendation",
  LabTest: "Lab test",
  LabTests: "Lab tests",
  Tests: "Tests",
  TestCode: "Test code",
  Addons: "Add-ons",
  AdditionalDetails: "Additional details",
  AllLabs: "All labs",
  PopularLabs: "Popular labs",
  NewLabRecommendation: "New lab recommendation",
  NewLab: "New lab",
  MarkersPanelsKits: "Markers, panels, and kits",
  SearchLabTests: "Search labs",
  SearchLabKits: "Search by kit, test, or company",
  SearchBiomarkers: "Search biomarkers",
  CloseLabsDrawer: "Close labs drawer",
  DeleteDraft: "Delete draft",
  DraftSaved: "Lab recommendation saved as draft!",
  EditLabTest: "Edit {{name}} lab test",
  Remove: "Remove",
  RemovedLabTestSuccessfully: "Test removed",
  RemovedPanelSuccessfully: "Panel removed",
  AddedTemplateSuccessfully: "Template added",
  RemovedTemplateSuccessfully: "Template removed",
  LabDrawFee: "Lab draw",
  NoLabRecommendations: "There are no lab recommendations for this {{patientTerm}}",
  PersonalInformationUpdated: "Personal information updated successfully",
  SomethingWentWrong: "Something went wrong, please try again",
  LabCompany: "Lab company",
  Company: "Company",
  LabCompanies: "Lab companies",
  PopularLabTests: "Popular lab tests",
  Result: "{{count}} result",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Result_plural: "{{count}} results",
  AddLabsToRecommendation: "Add labs to recommendation",
  ExploreLabCatalog: "Explore the lab catalog",
  Filters: "Filters",
  NewLabFor: "New lab recommendation for {{patientName}}",
  ByPractitioner: "By {{fullName}}",
  SentByPractitioner: "Sent by: {{fullName}}",
  Updated: "Updated {{date}}",
  ReceivedOn: "Plan received on {{date}}",
  IncludeMessage: "+ Include a personal message",
  Description: "Description",
  PatientInstructions: "{{patientTerm}} instructions",
  PatientInstructionsPDF: "{{patientTermCapitalized}} instructions PDF",
  PatientInstructionsDescription: "This test has instructions that can be",
  ViewedHere: "viewed here",
  ViewHere: "View here",
  Instructions: "Instructions",
  NotesFromPrac: "Notes from {{- pracFullName}}",
  SampleReport: "Sample report",
  ViewReport: "View report",
  TestType: "Test type",
  Overview: "Overview",
  BusinessDays: "business days",
  ShippingTime: "Shipping time",
  SampleProcessingTime: "Sample processing time",
  EstimatedDaysForResults: "Estimated days for results",
  EstimatedDaysForResultsWithDays: "Estimated days for results {{estimatedDaysForResults}}",
  MinimumAge: "Minimum age",
  SomeTestsHaveAndAgeRestriction:
    "Some tests in this recommendation are only available if you are older than {{age}}",
  CanceledJourneySubscriptionPatient: "Cannot order a canceled lab journey",
  StateRestrictions: "State restrictions",
  StateRestrictionDescription:
    "{{patientTermCapitalized}} can't order this test if they are located in {{labRestrictedStatesText}}",
  CantOrderInStates: "Can't order this test if located in {{labRestrictedStatesText}}",
  ReferenceRange: "Reference range",
  PatientRestrictions: "{{patientTermCapitalized}} restrictions",
  Summary: "Summary",
  MessageFromPractitioner: "Message from {{practitionerName}}",
  Biomarkers: "Biomarkers",
  Preparation: "Preparation",
  Location: "Location",
  NoLabDetailsFound: "No more details found for this test",
  PatientPreparation: "{{patientTermCapitalized}} preparation",
  CollectionInstructions: "Collection instructions",
  LabType: "Lab type",
  LabTypes: "Lab types",
  PhlebotomyTestLocation: "At {{company}} location (Phlebotomy required)",
  KitAndPhlebotomyTestLocation: "Phlebotomy is required for this test",
  AtHomeTest: "At home test",
  SampleType: "Sample type",
  SampleTypes: "Sample types",
  HealthCategory: "Health category",
  HealthCategories: "Health categories",
  CreateNewPatient: "Create new {{patientTerm}}",
  ChooseOrCreate: "Choose or create a {{patientTerm}} to order a test",
  SearchAllPatients: "Search all {{patientTermPlural}}...",
  NoPatientsFound: "No {{patientTermPlural}} found.",
  LabReportsComingSoon: "Lab reports coming soon",
  CreatePatientAndStartLab: "Create {{patientTerm}} and start lab recommendation",
  BestMatch: "Best match",
  AlphabeticalDescending: "Alphabetical (Z - A)",
  AlphabeticalAscending: "Alphabetical (A - Z)",
  NumberOfResultsIn: "{{numberOfResultsIn}} / {{totalRecommendations}} results in",
  AllResultsIn: "All results in",
  Pending: "Pending",
  NumOfPendingRecommendations: "{{amount}} pending",
  oneResult: "1 result",
  numOfResults: "{{amount}} results",
  NotifyPatient: "Notify {{patientTerm}}",
  CopyNotes: "Copy notes",
  CopyLink: "Copy link",
  DownloadPDF: "Download PDF",
  Download: "Download",
  DownloadResults: "Download results",
  DownloadRequisition: "Download requisition",
  CancelLabRecommendation: "Cancel lab recommendation",
  DeleteDraftRecommendation: "Delete draft recommendation",
  YesCancel: "Yes, cancel",
  YesDelete: "Yes, delete",
  AreYouSureCancelRecommendation:
    "Are you sure you want to cancel this lab recommendation? Your {{patientTerm}} won't be able to access it later.",
  AreYouSureDeleteDraftRecommendation:
    "Are you sure you want to delete this draft recommendation? All your changes in it will be lost.",
  SendLabRecommendation: "Send lab recommendation?",
  Send: "Send",
  AreYouSureSendDraftRecommendation:
    "Are you sure you want to send this draft recommendation to {{patientName}}? After sending it you won't be able to make any changes to it. You will only be able to cancel it if {{patientName}} has not paid for it.",
  SendToPatient: "Send to {{patientTerm}}",
  Items: "{{count}} item",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Items_plural: "{{count}} items",
  TotalAmount: "Total ${{total}}",
  SentToPatient: "Sent to {{patientTerm}}",
  PatientPaid: "{{patientTermCapitalized}} paid",
  ResultsIn: "Results in",
  Subtotal: "Subtotal",
  PhysicianAuthorizationSingle: "Physician Authorization",
  OrderingAndProcessingFeeSingle: "Lab processing fees",
  Total: "Total",
  DeletedDraftSuccessfully: "Draft recommendation deleted successfully!",
  ActivateDraftSuccessfully: "Lab recommendation sent successfully!",
  LabOrderPlainText: "Lab recommendation plain text",
  Message: "Message",
  Results: "Results",
  FlaggedBiomarkers: "Flagged biomarkers",
  UnflagBiomarker: "Unflag biomarker",
  FlagBiomarker: "Flag biomarker",
  AddNote: "Add note",
  ViewNote: "View note",
  AddedLabTestToDraftRecommendationSuccessfully: "Test added to draft lab recommendation!",
  CreateDraftLabRecommendationFirst:
    "You must create a draft lab recommendation to add this test to",
  Export: "Export",
  SendLinkDescription:
    "Copy and send this link to your {{patientTerm}}. They'll be prompted to sign in and view the lab recommendation you've sent them",
  LinkCopied: "Link copied to clipboard",
  ResendTheEmail:
    "Resend the lab recommendation email to your {{ patientTerm }} through Fullscript to remind them",
  ResendTheEmailPlan:
    "Resend the plan email to your {{ patientTerm }} through Fullscript to remind them.",
  NextStepBuildPlan: "Next step: Build a plan",
  RecommendSupplementsNext:
    "Recommend supplements or further lab testing based on your {{patientTerm}}'s results.",
  ReminderSent: "Reminder sent to {{- patientName}}",
  BuildPlan: "Build plan",
  Dismiss: "Dismiss",
  CancelledLabSuccessfully: "Lab recommendation cancelled successfully!",
  ViewRequisition: "View requisition",
  ViewRequisitionForm: "View requisition form",
  ViewPartialResults: "View partial results",
  ViewResults: "View results",
  ViewResultNotes: "View result notes",
  TrackShipment: "Track shipment",
  CannotCancelLabRecommendation: "Lab recommendation cannot be cancelled if it has paid items",
  Saved: "Saved",
  Save: "Save",
  InvalidDateOfBirth: "Lab work requires a minimum age of 6 years old",

  // Patient preview
  PatientPreview: "{{patientTermCapitalized}} preview",
  PatientPreviewTitle: "What {{- patientName}} will see",
  YourPatient: "your {{patientTerm}}",
  PatientPreviewIntroDescription:
    "Here's what your {{patientTerm}} will see after signing into Fullscript to review and place their lab orders.",
  PatientSignIn: "{{patientTermCapitalized}} sign in",
  PatientSignInDescription:
    "Your {{patientTerm}} will receive an email letting them know they have a lab recommendation from you. They'll be asked to sign in online — a super easy process where they enter their email and the password associated with their Fullscript account.",
  ViewingLabRecommendation: "Viewing the lab recommendation",
  ViewInLabs: "View in labs",
  ViewingLabRecommendationDescription:
    "Your {{patientTerm}} will be taken directly to an itemized list of your recommended lab orders under the \"My plan\" tab in their account. They can review the costs, download instructions, and view any messages or instructions you've included. Any supplements or wellness product {{treatmentPlanTermPlural}} you've sent to them will also appear here.",
  ProvidingRequiredInfo: "Providing required information",
  ProvidingRequiredInfoDescription:
    "If your {{patientTerm}} doesn't have their date of birth and biological sex listed on their account, they'll be prompted to provide it before they can add a lab recommendation to their cart. They'll also be required to review and accept the terms of service agreement for use of labs.",
  AddingItemsToTheirCart: "Adding items to their cart",
  AddingItemsToTheirCartDescription:
    'After completing the lab set up, your {{patientTerm}} will be able to add labs and any other recommended products to their cart. Once they\'re ready, they can click "continue to checkout" to proceed with placing their order.',
  PatientCheckout: "Patient checkout",
  PatientCheckoutDescription:
    "The {{patientTerm}} checkout experience is very straightforward! They'll fill out their shipping information, select a delivery method, and provide their payment information. They can review their order before placing it. Products and at-home test kits will be shipped to your {{patientTerm}}'s door.",
  ReceivingRequisition: "Receiving a requisition",
  ReceivingRequisitionDescription:
    "Once they've successfully placed their order, your {{patientTerm}} will receive a notification letting them know their requisition for lab draws will appear in their Fullscript account shortly. They'll receive an email from Fullscript once their requisition can be found in their \"My plan\" tab. This usually takes less than a minute.",
  TrackingProgressAndViewingResults: "Tracking progress and viewing results",
  TrackingProgressAndViewingResultsDescription:
    'Once they have placed their order, your {{patientTerm}} will be able to track the labs process under their "My plan" tab. Each lab that they have ordered will show a progress bar that indicates whether the order is processing, if their requisition is ready for download, whether they can book their lab appointment, and whether their lab results are in. Once their results are ready, your {{patientTerm}} can view them in Fullscript.',
  PatientDoesntMeetTheRequiredAge: "{{patientTerm}} doesn't meet the age requirement for labs",
  PatientHasMissingInformation: "{{patientTerm}} has missing information required for labs",
  PatientsUnder18Restricted:
    "{{patientTermPlural}} who are under 18 years old are not eligible to order lab tests.",
  AddLabs: "Add labs",
  ChooseFromOverXLabTests: "Choose from over 700 lab tests",
  ViewCertificate: "View",
  NpiTooShort: "NPI number must be 10 digits",
  Test: "Test",
  GetResults: "Get results",
  TakeAction: "Take action",
  CompleteYourFullBodyTesting: "Complete your full-body testing",
  RepeatThisCycle: "Repeat this cycle every [{{frequency}}] months",
  Repeat: "Repeat",
  ReceiveAnInterpretation: "Receive an interpretation of results from your practitioner",
  AddressHealthConcerns: "Address health concerns with lifestyle changes and supplements",
  TestCenter: "Test center",
  TestCenterLab: "Test center lab",
  InHomeKit: "In-home kit",
  NextSteps: "Next steps",
  LabVial: "Lab vial",
  SuccessfullyOrderedTestCenter: "You have successfully paid for your test center tests.",
  WaitForRequisition:
    "When your requisition is ready, we will send you an email with further instructions on how to book your test.",
  CompleteLabSetupBanner:
    "Before proceeding with a lab recommendation, you'll need to complete labs setup",
  CompleteLabInfoPatient: "Before ordering labs you'll need to complete labs setup",
  ExtraInfoRequiredBanner:
    "Before proceeding with a lab recommendation, you'll need to provide us with more information",
  ProvideInformation: "Provide information",
  StoreOwnerCompleteLabSetupBanner:
    "You will only be able to send a lab recommendation once your store owner completes set up for labs.",
  CompleteSetup: "Complete setup",
  AgreeToTermsOfService:
    "Before proceeding with a lab recommendation, you'll need to agree to the terms and services agreement",
  SeeTermsOfService: "See terms of service",
  ErrorUpdatingNpi: "Error while updating NPI",
  ErrorUpdatingCredentialsUsedForLabs: "Error while updating credentials to use for labs",
  SuccessUpdatingNpi: "Updated NPI successfully",
  LabsInformationChanged: "Updated lab settings successfully",
  CompleteOnboardingFirst: "Please complete labs setup",
  StoreOwnerCompleteOnboardingFirst: "Your account owner needs to complete labs setup",
  CompleteOnboardingFirstAddingTest: "You need to complete labs setup before adding this test",
  StoreOwnerCompleteOnboardingFirstAddingTest:
    "Your account owner needs to complete labs setup before you can add this test",
  AddTestsFirst: "You need to add at least one test",
  AccountOwnerRestrictedFromTest: "You cannot access this test based on account owner credentials",
  FailedLabsPractitionerVerification: "You are not eligible to use labs",
  NoProductsOrTests: "Please edit the plan to add products or tests",
  ParentOfAddonNotInPlan: "Add the parent test to the plan first",
  ActivatedPlanCannotAddTest: "Cannot add lab tests to an active plan",
  ActivatedPlanCannotRemoveTest: "Cannot remove lab tests from an active plan",
  CannotRemovePurchasedTest: "Cannot remove purchased lab tests",
  CannotUpdatePurchasedTest: "Cannot update purchased lab tests",
  CannotUpdateJourneyInActivePlan: "Cannot update a lab journey in an active plan",
  CannotRemoveJourneyInActivePlan: "Cannot remove a lab journey from an active plan",
  CannotRemovePurchasedPanel: "Cannot remove purchased panels",
  CannotRemovePurchasedTemplate: "Cannot remove purchased templates",
  CannotAddQuestTestToPurchasedQuestRecommendation:
    "Cannot add Quest test to purchased Quest recommendation",
  RegisterForLabs: "Register for labs",
  UploadHealthCareCredentialsToAccess: "Upload healthcare credentials to access",
  PromptingToRegisterLabsAtFullscriptOriginalWebsite:
    "To register for Labs, please sign in to Fullscript and register from <originalSiteLink>this link</originalSiteLink>.",
  PendingCertificationApproval:
    "You will be able to register for labs after your certification has been reviewed by our team.",
  PendingStoreCertificateApproval:
    "You can register for labs once your store owner completes their account setup.",
  PendingPhoneNumber:
    "A phone number is required in your account before you can register for labs.\n\n<phoneNumberLink>Add phone number</phoneNumberLink>",
  CompleteMissingInformation: "Add license information",
  CompleteMissingInformationDescription:
    "Your license information and clinic address help determine which lab tests you can recommend without using an authorization network.",
  OwnerCompleteMissingInformation: "Fullscript account address information missing",
  OwnerCompleteMissingInformationDescription:
    "Your Fullscript account owner needs to enter address information before you can order lab tests without using an authorization network.",
  AddAdditionalInformation: "Add license",
  StartPrescribingLabs:
    "You can start {{treatmentPlanTermPastParticiple}} labs to your {{patientTermPlural}} through Fullscript.",
  EnterNpiAndLicenseInfo: "Enter your NPI and license information",
  EnterClinicInformation: "Enter your clinic information",
  EnterClinicInformationDescription:
    "Some lab companies require your clinic information to recommend lab tests without using an authorization network.",
  CannotContinueLabsOnboardingRestrictedState: "Agree to use Labs in a restricted State first",
  LabsNotAvailableForPatientsInRestrictedStates:
    "Labs are not available for {{patientTerm}} in {{selectedState}}",
  LabsNotAvailableForPatientsInRestrictedStatesDescription:
    "{{patientTermCapitalized}} located in the following states can't order labs on Fullscript:",
  LabsNotAvailableForPatientsInRestrictedStatesAgreement:
    "I understand that {{patientTerm}} located in these states can't order labs on Fullscript.",
  CompleteAddressFirst: "Enter all required fields",
  UpdatedClinicAddressSuccessfully: "Clinic address updated successfully",
  EnterNpiAndLicenseInfoDescription:
    "Your NPI and license information determines which lab tests you can recommend without using an authorization network.",
  VerifyYourInformation: "Verify your information",
  RegisterForAuthNetwork: "Register for the authorization network",
  AnAuthNetworkRequired:
    "A network of licensed practitioners will authorize this test and review the results.",
  PerPanelOrSpecialtyTest: "${{authNetworkFee}} per panel or specialty test",
  QuestFeeApplies: "Quest fee applies",
  LabCompanyFeeDescription: "<blur>${{labCompanyFee}}</blur> per panel",
  ReminderTextSend: "Reminder text sent",
  ReminderEmailSend: "Reminder email sent",
  RegisterForAuthNetworkDescription:
    "Some lab tests require you to use the authorization network. The authorization network will cost your {{patientTerm}} {{fee}} per panel or speciality test.",
  RegisterForNetwork: "Register for network",
  LabTestsAuth: "Register to use the authorization network",
  YourPractitionerLicense:
    "You're required to use an authorization network to {{treatmentPlanTerm}} labs. A network of licensed practitioners will authorize labs you send and review test results.",
  YourPractitionerLicenseSomeButNotAll:
    "Your practitioner license allows you to {{treatmentPlanTerm}} some lab tests, but not all. For lab tests you're not authorized to {{treatmentPlanTerm}}, you can use a network of licensed practitioners to authorize the lab and review test results.",
  TheAuthorizationNetworkWillCost:
    "The authorization network will cost your {{patientTerm}} $12.50 per-panel or speciality test – charged as an additional fee when they're placing their order.",
  UsingTheAuthorizatioNetworkWillEnsure:
    "Using the authorization network will ensure compliance with legal and medical standards.",
  LabReviewToS: "Review the terms of service for labs with Fullscript",
  LabReviewBaa: "Review and sign the Business Associate Agreement (BAA)",
  BaaAgreedAndSignature: "Submit",
  SignedBaaSuccessfully: "Successfully signed BAA agreement.",
  FailedToGetUrl: "Failed to get URL",
  ScrollToBottom: "Scroll to the bottom of the agreement above to enable this checkbox",
  AgreeToTermsFirst: "Scroll through the document before you can agree",
  ScrollThroughTheDocument: "Scroll through the document and agree to terms of service to continue",
  AgreeToBaaFirst: "You need to agree to the Business Associate Agreement first",
  SignBaaFirst: "You need to sign the Business Associate Agreement first",
  FirstName: "First name",
  LastName: "Last name",
  PhoneNumber: "Phone number",
  IfYouHaveAnNpi: "If you have an NPI number, the name you enter must match it",
  ContactSupport: "Contact support",
  LearnMore: "Learn more",
  LabsSetup: "Labs setup",
  Next: "Next",
  IAgree: "I agree",
  IAgreeToTheTermsOfService: "I agree to the terms of service",
  IAgreeToUseAuth: "I agree to use the authorization network for ordering labs.",
  ErrorAgreeingToUseAuthNetwork:
    "An error occurred while agreeing to use the authorization network. Try again later",
  StepOneOfTwo: "Step 1/2",
  StepTwoOfTwo: "Step 2/2",
  AgreeToTerms: "Please read the terms of service agreement for use of labs",
  ErrorAgreeingToTos: "Error agreeing to terms of service",
  LabsAgreement1:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est labount ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est labount ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est labount ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
  LabsAgreement2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est labount ut labore et dolore magna aliqua",
  OpenInstructions: "Open instructions",
  AdditionalInstructions: "Additional instructions",
  MyInformation: "My information",
  LabsRequireYourInformation:
    "Labs require your biological sex and date of birth for identification and processing of any orders.",
  LabsRequireYourSex:
    "Labs require your biological sex for identification and processing of any orders.",
  LabsRequireYourDateOfBirth:
    "Labs require your date of birth for identification and processing of any orders.",
  RequisitionTooltip:
    "When your requisition is ready, we will send you an email with further instructions on how to book your test.",
  RequisitionReady: "Requisition ready. Book now.",
  RequisitionReadySimple: "Requisition ready",
  WaitingForRequisition: "Waiting for requisition",
  ProcessingOrder: "Processing order",
  ResultsInOn: "Results in on {{resultsInOnDate}}",
  Shipped: "Shipped",
  ExcludedFromDiscountAndFreeShipping: "Excluded from discount and free shipping",
  Yes: "Yes",
  No: "No",
  TestNoLongerAvailable: "This test is no longer available",
  AdditionalFeeNote:
    "Please note: Additional processing fees will be added to your order. You can review these fees and your order total at checkout.",
  BeforeYouGetStarted:
    "Before you get started, we wanted to make sure you're aware of a couple of things",
  BusinessAssociateAgreement: "Business associate agreement",
  RestrictionsForLabs: "Restrictions for labs",
  PaymentThroughInsurance: "Payment through insurance",
  NotEligibleForReimbursement: "This is not eligible for reimbursement through insurance",
  LabsDescriptionLoremIpsum:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ",
  Details: "Details",
  ViewMoreInfo: "View more information",
  BloodTestsFromMultipleLabCompanies: "Blood tests from multiple lab companies",
  BloodTestFromMultipleCompaniesMessage:
    "You are including blood tests from multiple lab companies. Your patient will need separate blood draws for each and will get separate result PDFs for these labs.",
  Templates: "templates",
  MultiPatientPlans: "multi-{{patientTerm}} plans",
  AddLabToPlanLimitation: "Lab recommendations are not available for {{planTypeTerm}}",
  JourneyCancelled: "Journey cancelled",
  RemovingJourneySubscription: "Removing journey subscription from plan",
  CancellingJourneySubscription: "Cancelling journey subscription",
  CancelJourney: "Cancel Journey",
  CancelJourneyQuestion: "Cancel Journey?",
  CancelJourneyPatientDescription:
    "This test is only available for purchase through your practitioner. If you cancel and want to resubscribe, you'll have to contact your practitioner.",
  EditJourneyInformationDescriptions:
    "To edit your billing information or credit card details, contact Fullscript support. They can make these changes for you.",
  YouCanCancelForPatient:
    "You can cancel this Journey for your {{patientTerm}}. They'll be notified by email.",
  ChangeTheFrequencyOfTesting:
    "To change the frequency of testing or type of test, cancel this Journey and recommend a new one to your {{patientTerm}}.",
  ThisTestIsOnlyAvailableForPurchase:
    "This test is only available for purchase through your practitioner. If you cancel and want to resubscribe, you'll have to contact your practitioner.",
  ToEditBilling:
    "To edit your billing information or credit card details, contact Fullscript support. They can make these changes for you.",
  KeepJourney: "Keep journey",
  AddingJourneySubscription: "Adding journey subscription to plan",
  NotAllowedToOrder: "Signup for labs to order this test",
  OnlyQuestTestsAllowedInPanels: "Can only add Quest tests to custom panels",
  NotAllowedToOrderMissingLicense: "Add your license information to order this test",
  RequiresPhysicianNetwork: "Authorization network fee applies",
  RequiresPhysicianNetworkDescription: "<blur>{{authFee}} per panel or speciality test</blur>",
  PhysicianNetworkPatientDescription:
    "This is the fee charged by a physician network to sign the requisition",
  TheFeeIsForAThirdPartyPhysicianAuthorization:
    "The fee is for a third-party physician to authorize and review each test and its results.",
  NotAllowedToOrderWithoutPhysicianNetwork: "Register for authorization network",
  NotAllowedToOrderWithoutPhysicianNetworkAsClerk: "Authorization network required",
  CompleteLabsOnboardingBeforeAddingTest: "Labs registration required",
  AddATestAndNameYourPanelToCreate: "Add a test and name to create a custom panel",
  BuildCustomPanel: "Build custom panel",
  BuildTemplate: "Build template",
  BuildTemplateDisabled: "Please add lab tests below",
  CustomPanel: "Custom panel",
  CustomPanels: "Custom panels",
  TemplatesAndPanels: "Templates and panels",
  PanelTotal: "Total starts at",
  PlanReviewFees: "Fees applied at plan review",
  FeesIncludedInSubtotal: "Fees are included in subtotal*",
  AddNotes: "Add notes",
  AuthorizationNetwork: "Authorization network fee",
  ViewAll: "View all",
  ViewMore: "View more",
  AuthNetworkFee: "Authorization network fee",
  CreateCustomPanel: "Create custom panel",
  SaveCustomPanel: "Save custom panel",
  PlanTypeNotAvailable: "Not available because labs are in the plan",
  AddToPanel: "Add to panel",
  EmptySearch: "We didn't find any labs that match your search",
  BiomarkerSummary: "Biomarker summary",
  NoBiomarkersAdded: "No biomarkers added",
  SaveAsCustomPanel: "Save as custom panel",
  SuccessfullyDeletedPanel: "Deleted panel successfully",
  PanelName: "Panel name",
  CustomPanelName: "Custom panel name",
  QuestPanel: "Quest panel",
  SaveAsCustomPanelModal: {
    title: "Save as custom panel",
    cta: "Create panel",
    success: "Saved as panel successfully",
  },
  RemoveTestFromPanel: "Remove {{name}} from panel",
  PanelSaved: "Panel saved",
  PanelCreatedSuccessfully: "Panel created successfully",
  PanelSavedSuccessfully: "Panel saved successfully",
  TestsIncluded: "Tests included",
  UntitledPanel: "Untitled panel",
  InformationRequired:
    "Information is required before your first lab order. This will only be required once.",
  EditInformation: "Edit information",
  EnterInformation: "Enter information",
  InformationRequiredForLab: "{{patientTerm}} information required for lab orders",
  PatientInformationRequiredForLab: "Information required for lab orders",
  LegalName: "Legal name",
  MatchGovernmentID: "Your name must match your government issued ID.",
  PatientMatchGovernmentID: "{{patientTerm}} name must match their government issued ID.",
  GovernmentIDRequired:
    "If a sample collection is required, you'll need to present a government issued ID at the testing center. Example: driver's license",
  PatientGovernmentIDRequired:
    "If a sample collection is required, your {{patientTerm}} need to present a government issued ID at the testing center. Example: driver's license",
  OtherRequiredInformation: "Other required information",
  DateOfBirth: "Date of birth",
  InvalidDateFormat: "Invalid date format. (mm/dd/yyyy)",
  BiologicalSex: "Biological sex",
  CannotEditAnActiveSubscription: "You cannot edit a subscription in a plan",
  Female: "Female",
  Male: "Male",
  Terms: "I accept Fullscript Lab's <2>Terms & Conditions</2>",
  PatientTerms:
    "My {{patientTerm}} accepts the <termsLink>Terms & Conditions</termsLink> of labs with Fullscript",
  Submit: "Submit",
  CompanyNameBeforePanel: " panel",
  IneligibleToLabs:
    "You are not eligible to order this lab due to an age restriction. Patients must be at least 18 years old. Contact {{pracName}} for next steps.",
  WhatIsBeingTested: "What's tested",
  FastingRequired: "Fasting required",
  RequiresFasting: "Requires fasting",
  journeyCanceledByNote: "Canceled by {{canceledBy}} on {{canceledAt}}.",
  JourneysDescriptionNoteShortened: "Payment will recur every {{frequency}}",
  PractitionerNotes: "{{- practitionerName}}'s notes:",
  SentEmailInstructions: "Email with lab instructions sent successfully",
  SentEmailRequisition: "Email with lab requisition sent successfully",
  SentTextInstructions: "Text with lab instructions sent successfully",
  SentTextRequisition: "Text with lab requisition sent successfully",
  SendPreparationInstructionsTitle: "Send preparation instructions to {{patientTerm}}",
  SendRequisitionTitle: "Send requisition to {{patientTerm}}",
  SendTextMessageTitle: "Send text message",
  SendPreparationInstructionsDescription:
    "Send your {{patientTerm}} a link to log in to Fullscript to view their test instructions.",
  SendRequisitionDescription:
    "Send your {{patientTerm}} a link to log in to Fullscript to view their requisition.",
  SendTextMessageDescription:
    "Send a text message through Fullscript. Standard message rates apply.",
  SendEmail: "Send email",
  SendEmailDescription: "Send an email to {{email}} through Fullscript.",
  InProgress: "In progress",
  ViewDetails: "View details",
  BookAppointment: "Book appointment",
  TestSummary: "Test summary",
  TestCompleted: "Test completed",
  AboutTheTest: "About the test",
  Tested: "Tested {{date}}",
  ResultsFrom: "Results from {{companyName}}.",
  Eligibility: "Eligibility",
  PreparingForYourTest: "Preparing for your test",
  AddonsAvailable: "Add-ons available",
  Addon: "Add-on",
  CannotOrderInStateLabsOnly:
    "Labs can't be ordered in the state of {{state}}. Please contact your practitioner for next steps.",
  CannotOrderInStateLabsWithSupplements:
    "Labs can't be ordered in the state of {{state}}. Please remove the lab(s) from your cart before placing your order.",
  CannotOrderInThisState: "Labs can't be ordered in this state",
  TestResults: "Test results",
  ViewAmendedResults: "View amended results",
  Phlebotomy: "Phlebotomy",
  PhlebotomyNotRequired: "Phlebotomy: not required",
  NotRequired: "Not required",
  Required: "Required",
  PhlebotomyCheckoutSteps: "Step {{currentStep}}/2",
  OrderOfEvents: "Order of events",
  ShowAllEvents: "Show all events ({{count}})",
  HideEvents: "Hide events",
  DiscountsNotApplied: "Discounts do not apply to labs.",
  TestCount: "{{testCount}} test",
  AddedToFlaggedBiomarkers: "Added to flagged biomarkers",
  RemovedFromFlaggedBiomarkers: "Removed from flagged biomarkers",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TestCount_plural: "{{testCount}} tests",
  BackToPersonalInfo: "Back to personal information",
  PaymentAndCancellationPolicy: "Payment and cancellation policy",
  YouWillBeCharged:
    "You will be charged every 3, 6, or 12 months, depending on the testing frequency your provider has selected. After each payment, you'll receive a digital requisition for your upcoming test. Then, you can proceed with booking an appointment at your nearest blood draw location.",
  YourPatientWillBeCharged:
    "Your {{patientTerm}} will be charged every 3, 6, or 12 months, depending on the testing frequency your provider has selected. After each payment, they'll receive a digital requisition for their upcoming test. Then, they can proceed with booking an appointment at their nearest blood draw location.",
  PaymentsWillBeCharged:
    "Payments will be charged automatically on the card you made the original purchase with.",
  PaymentsWillBePatientCharged:
    "Payments will be charged automatically on the card the {{patientTerm}} made the original purchase with.",
  YouOrYourPractitionerCanCancel:
    "You or your practitioner can cancel the Journey at any time, at no charge.",
  YouOrYourPatientCanCancel:
    "You or your {{patientTerm}} can cancel the Journey at any time, at no charge.",
  ErrorToPaymentStep:
    "An error occurred while proceeding to the payment step. Please try again later.",
  KeepTrackOfYourLab: "Keep track of your lab",
  LabRecommendationDetails: {
    Title: "Test details",
    BackButton: "Back",
    PractitionerPlain: "Practitioner: {{practitionerName}}",
    WhatsTested: "What's tested ({{testCount}}):",
    LinksAndDocuments: "Links and documents",
    TestHistory: "Test history",
    TestHistoryViewResults: {
      Results: "View results",
      PartialResults: "View partial results",
      ResultsAmended: "View amended results",
    },
    TestHistoryDescription: {
      SentToPatient: "Sent to {{patientTerm}}",
      Purchased: "Purchased",
      ApprovedAuthNetwork: "Approved by authorization network",
      RejectedByAuthNetwork: "Rejected by authorization network",
      SpecimenIssue: "Specimen issue",
      RequisitionReady: "Requisition ready",
      Processing: "Processing at lab",
      PartialResultsAvailable: "Partial results available",
      PartialResultsSharedWithPatient: "Partial results shared with {{patientTerm}}",
      AllResultsAvailable: "All results available",
      AllResultsSharedWithPatient: "Results shared with {{patientTerm}}",
      ShippedToPatient: "Shipped to {{patientTerm}}",
      DeliveredToPatient: "Delivered to {{patientTerm}}",
      ResultsAmended: "Results amended",
      PartialResults: "Partial results",
      Results: "Results",
      AllResults: "All results",
      AmendedResults: "Amended results",
      InitialPatientRelease: "Interpretation of results shared with patient by {{providerName}}.",
      SubsequentPatientRelease:
        "Updated interpretation of results shared with patient by {{providerName}}.",
      AutosendPatientRelease: "Results automatically shared with patient",
      ScheduleAppointment: "Awaiting appointment",
      UpcomingAppointment: "Appointment scheduled",
      CancelledAppointment: "Appointment cancelled",
      PurchasedInOffice: "Purchased in office",
    },
    PatientTestHistoryDescription: {
      RejectedByAuthNetwork: "Rejected by authorization network",
      PlanReceived: "Plan received",
      Purchased: "Purchased",
      ApprovedAuthNetwork: "Approved by authorization network",
      RequisitionReady: "Requisition ready",
      Processing: "Processing at lab",
      PartialResultsAvailable: "Partial results available",
      PartialResultsSharedWithPatient: "Partial results shared",
      AllResultsAvailable: "All results available",
      AllResultsSharedWithPatient: "Results shared with you",
      ShippedToPatient: "Shipped",
      DeliveredToPatient: "Delivered",
      ResultsAmended: "Results amended",
      SpecimenIssue: "Specimen issue",
      InitialPatientRelease: "Interpretation of results available",
      SubsequentPatientRelease: "Interpretation of results updated",
      AutosendPatientRelease: "Interpretation of results available",
      PartialResults: "Partial results",
      Results: "Results",
      ScheduleAppointment: "Awaiting appointment",
      UpcomingAppointment: "Appointment scheduled",
      CancelledAppointment: "Appointment cancelled",
      PurchasedInOffice: "Purchased in office",
    },
    ViewPlan: "View plan",
    PreparationInstructions: "Preparation instructions",
    EmptyPreparationInstructions: "No specific preparation instructions for this test",
    AdditionalInstructions: "Additional instructions",
    Requisition: "Requisition",
    DownloadResults: "Download results",
    CopyPatientInstructionsLink: "Copy {{patientTerm}} instructions link",
    CopyLinkSuccess: "Link copied",
    SendPatientInstructionsIcon: "Send {{patientTerm}} instructions",
    DownloadRequisition: "Download requisition",
    CopyRequisitionLink: "Copy requisition link",
    CopyPatientInstructions: "Copy {{patientTerm}} instructions",
    SendRequisitionIcon: "Send requisition to {{patientTerm}}",
    CantFindRecommendation:
      "Could not find the specified lab recommendation: {{ recommendationId }}",
  },
  LabInterpretationDetails: {
    DownloadOriginalReport: "Download original report",
    DownloadResults: "Download results",
    ResultsPDF: "Results.pdf",
    Title: "Interpretation of results",
    BackButton: "Back",
    TestedOn: "Tested {{testDate}}",
    SummaryTitle: "Summary",
    OutsideStandardRange:
      'Your {{patientTerm}} will receive this message: "Some of your test results are outside of the standard range. You\'re advised to consult with your primary care provider to discuss these results."',
    EditSection: {
      SentOn: "Sent to {{patientTerm}} on {{date}}",
      EditButton: "Edit interpretation",
    },
    AllBiomarkers: {
      BiomarkerMessage: "Biomarker message for {{- biomarkerName}}",
      Title: "All biomarkers",
      AddAMessage: "Add a message",
      Search: {
        SearchPlaceholder: "Search biomarkers",
        ClearSearch: "Clear search",
        EmptySearchResultsHeader: "No results found",
        EmptySearchResultsMessage: "Clear your search or try adjusting the filters applied.",
      },
      Filter: {
        HealthCategoryFilterLabel: "Health area",
        BiomarkerStatusFilterLabel: "Marker status",
        BiomarkerFilterAll: "All",
        BiomarkerFiltersSelected: "{{count}} selected",
      },
      Table: {
        CategoryColumnName: "Health area",
        BiomarkerColumnName: "Biomarker",
        ResultColumnName: "Result",
        OptimalRangeColumnName: "Optimal range",
        UnitsColumnName: "Units",
        StatusColumnName: "Status",
        TrendColumnName: "Trend",
        AboutYourPatientsResult: "About your {{-patientTermPossessive}} result",
        WhatIsBiomarker: "What's {{- biomarkerName}}?",
        HowWeDetermineOptimalRange: "How we determine the optimal range",
        InRange: "In range",
        SubOptimal: "Suboptimal",
        OutOfRange: "Out of range",
        AddMessage: "Add a message",
        ContactSupport: "contact Fullscript support.",
        RangesNotDefined: "Ranges for this biomarker are not defined.",
        NoMatchedRange: "There was no matching range for this biomarker.",
        ResultIsDNR:
          'The result for this biomarker is "do not report". Download the original report to view more details, or',
        ResultUnavailable:
          "This result is unavailable. Download the original report to view more details, or",
      },
    },
    AreasOfConcern: {
      Title: "Flagged biomarkers",
      EmptyStateContent:
        "Flag biomarkers you want to focus on with your {{patientTerm}}, such as an area of concern.",
    },
    Message: {
      Title: "Message for {{patientTerm}}",
      SuccessMessage: "Successfully updated summary for {{patientTerm}}",
      SuccessMessageBiomarker: "Successfully updated summary for {{- biomarkerName}}",
      Placeholder: "Add a message",
    },
    OptimalRangeSources: {
      Title: "Sources used to determine the optimal range",
      Description:
        "Fullscript medical team combined research to determine the optimal biomarker range.",
      SourcesTitle: "Sources:",
    },
    Patient: {
      Summary: {
        Title: "All results",
        ViewAll: "View all",
      },
      OutsideStandardRange:
        "Some of your test results are outside of the standard range. You're advised to consult with your primary care provider to discuss these results.",
    },
    Summary: {
      Title: "Results by health area",
    },
    AllResults: {
      Title: "All results",
    },
    SendToPatient: {
      Title: "Send interpretation of results to {{ patientTerm }}",
      ResendTitle: "Send updated interpretation of results to {{ patientTerm }}",
      OutOfRangeAck:
        "I acknowledge that my {{patientTerm}} has results that are out of range and should consult with their primary care provider.",
      ButtonText: "Send to {{ patientTerm }}",
      SuccessMessage: "Interpretation shared",
    },
    DownloadModal: {
      Title: "Select version to download",
      OriginalReport: "Original report",
      InterpretationOfResults: "Interpretation of results",
      ScreenshotOfInterpretedReport: "Screenshot of interpreted lab pdf report",
      ScreenshotOfOriginalReport: "Screenshot of original lab pdf report",
      FullscriptLeafLogo: "Fullscript leaf logo",
    },
  },
  LabResultStatusPrac: {
    NotPurchased: "Not purchased",
    Canceled: "Canceled",
    Purchased: "Purchased",
    Approved: "Approved",
    Incomplete: "Incomplete",
    RequisitionReady: "Requisition ready",
    ShippedToPatient: "Shipped to {{patientTerm}}",
    Shipped: "Shipped",
    Delivered: "Delivered",
    DeliveredToPatient: "Delivered to {{patientTerm}}",
    ProcessingAtLab: "Processing at lab",
    PartialResults: "Partial results available",
    PartialResultsShared: "Partial results shared with {{patientTerm}}",
    ResultsAvailable: "Results available",
    InterpretationReady: "Interpretation ready",
    InterpretationShared: "Interpretation shared",
    ResultsShared: "Results shared with {{patientTerm}}",
    ResultsAmended: "Results amended",
    SentToPatient: "Sent to {{patientTerm}}",
    ResultsAmendedShared: "Amended results shared with {{patientTerm}}",
    Rejected: "Rejected by authorization network",
    Expired: "Expired",
    Refunded: "Refunded",
    SpecimenIssue: "Specimen issue",
    Complete: "Complete",
    Upcoming: "Upcoming",
    PurchasedInOffice: "Purchased in office",
  },
  LabResultOrderDate: "To be charged {{date}}",
  LabResultStatusPatient: {
    Canceled: "Canceled",
    DeliveredToPatient: "Delivered",
    Expired: "Expired",
    Incomplete: "Incomplete",
    InterpretationShared: "Interpretation ready",
    NotPurchased: "Not purchased",
    PartialResults: "Partial results available",
    ProcessingAtLab: "Processing at lab",
    Purchased: "Purchased",
    Refunded: "Refunded",
    Rejected: "Rejected by authorization network",
    RequisitionPending: "Requisition pending",
    RequisitionReady: "Requisition ready",
    ResultsAmended: "Results amended",
    ResultsAvailable: "Results available",
    ScheduleAppointment: "Schedule appointment",
    ShippedToPatient: "Shipped",
    SpecimenIssue: "Specimen issue",
    UpcomingAppointment: "Upcoming appointment",
    PurchasedInOffice: "Purchased in office",
  },
  LabResultEventsPatient: {
    RequisitionPending: "Requisition pending",
    RequisitionPendingDescription:
      "You'll receive your requisition within 24 hours of purchasing the test.",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RequisitionPendingDescription_plural:
      "You'll receive your requisition within 24 hours of purchasing the tests.",
    CompleteYourTest: "Complete your test",
    CompleteYourTestDescription:
      "Follow the <1>instructions</1> to collect your sample. Then, ship it back to the lab company.",
    CompleteYourAtHomePortion: "1. Complete the at-home portion of your test",
    CompleteYourAtHomePortionDescription:
      "Follow the <1>instructions</1> to collect your sample(s).",
    ManageRecurringTestLink: "Manage your Journey in <>Labs</>",
    CompleteYourBloodDrawStep2: "2. Complete your sample collection",
    CompleteYourBloodDraw2: "Complete your sample collection",
    CompleteYourBloodDraw2Description: "This test requires a blood draw.",
    CompleteYourBloodDraw2Description2:
      "Fullscript has partnered with GetLabs to make it easy for you to book a mobile blood draw. A mobile phlebotomist can come to your location and complete your sample collection. ",
    CompleteYourBloodDraw2Description3: "Book your appointment with GetLabs.",
    CompleteYourBloodDraw2Description4:
      "Please note that an additional service fee will apply, payable directly to GetLabs.",
    CompleteYourBloodDraw2Description5:
      "If you need assistance finding an alternative option for your blood draw, contact <supportLink>Fullscript support</supportLink>.",
    CompleteYourBloodDraw: "Complete your sample collection",
    RequisitionReady: "Your requisition is ready",
    RequisitionReadyDescription:
      "You must have your requisition with you at your sample collection. Printed and mobile versions are both accepted.",
    Purchased: "Test purchased",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Purchased_plural: "Tests purchased",
    PurchasedDescription: "You'll receive a tracking number when we ship the test to you.",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PurchasedDescription_plural: "You'll receive a tracking number when we ship the tests to you.",
    ShippedToPatient: "Test shipped",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShippedToPatient_plural: "Tests shipped",
    ShippedToPatientDescription: "You'll receive your test in 3–4 day(s).",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShippedToPatientDescription_plural: "You'll receive your tests in 3–4 day(s).",
    DeliveredToPatient: "Test delivered",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DeliveredToPatient_plural: "Tests delivered",
    DeliveredToPatientDescription:
      "Go to any lab near you to complete your sample collection. You must have a printed or mobile version of your requisition with you.",
    DeliveredToPatientDescription2:
      "Bring your test package to the sample collection appointment. Your local lab will usually ship all samples back to the lab company for you.",
    DeliveredToPatientDescription3:
      "Note: Your local lab will charge a fee to do the sample collection.",
    YourTestIsProcessingAtLab: "Your test is processing at the lab",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    YourTestIsProcessingAtLab_Plural: "Your tests are processing at the lab",
    ProcessingAtLab: "Processing at lab",
    ProcessingAtLabDescription:
      "The lab company is processing your test. {{processingTime}}. You'll receive an email when your practitioner shares your results.",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ProcessingAtLabDescription_plural:
      "The lab company is processing your tests. {{processingTime}}. You'll receive an email when your practitioner shares your results.",
    PartialResults: "Partial test results available",
    PartialResultsDescription:
      "Some of your test results are available, but haven't been reviewed by your practitioner yet.",
    PartialResultsShared: "Your practitioner shared partial test results",
    PartialResultsSharedDescription: "Some of your test results are ready to view.",
    ResultsAvailable: "All test results available",
    ResultsAvailableJourney: "An interpretation of your results is coming soon",
    ResultsAvailableDescription:
      "Test results are available, but haven't been reviewed by your practitioner yet.",
    ResultsAvailableShared: "Your practitioner shared your test results",
    ResultsAvailableSharedDescription: "Your test results are ready to view.",
    ResultsAvailableSharedDescriptionJourney:
      "Your practitioner will share an interpretation of your results soon. In the meantime, you can view partial results in a PDF format.",
    InterpretationResultsAvailableShared: "Results available",
    InterpretationResultsAvailableSharedDescription:
      "Your practitioner shared your results on {{date}}.",
    InterpretationResultsAvailableAutosentDescription: "Your results where shared on {{date}}.",
    ResultsAmended: "Amended test results available",
    ResultsAmendedDescription:
      "Your test results were amended by the lab company. The amended results haven't been reviewed by your practitioner yet.",
    ResultsAmendedShared: "Your practitioner shared amended test results",
    ResultsAmendedSharedDescription:
      "Your test results were amended by the lab company. Updated results are available to view.",
    Rejected: {
      title: "Test rejected by authorization network",
      body: "You will receive a full refund. Please contact your practitioner if you have any questions.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Rejected_plural: {
      title: "Tests rejected by authorization network",
      body: "You will receive a full refund. Please contact your practitioner if you have any questions.",
    },
    Expired: { title: "Test expired", body: "This test expired on {{date}}." },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Expired_plural: { title: "Tests expired", body: "These tests expired on {{date}}." },
    Incomplete: {
      title: "Test incomplete",
      body: "The test couldn't be processed due to a specimen issue. Please contact your practitioner for next steps.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Incomplete_plural: {
      title: "Tests incomplete",
      body: "The tests couldn't be processed due to a specimen issue. Please contact your practitioner for next steps.",
    },
    SpecimenIssue: {
      title: "Specimen issue",
      body: "The test couldn't be processed due to a specimen issue. Please contact your practitioner for next steps.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SpecimenIssue_plural: {
      title: "Specimen issue",
      body: "The tests couldn't be processed due to a specimen issue. Please contact your practitioner for next steps.",
    },
    Refunded: {
      title: "Test refunded",
      body: "The {{patientTerm}} was refunded for this test on {{date}}. ",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Refunded_plural: {
      title: "Tests refunded",
      body: "The {{patientTerm}} was refunded for these tests on {{date}}. ",
    },
    Buttons: {
      ViewRequisition: "View requisition",
      ViewResults: "View results",
      DownloadResults: "Download results",
      ViewInterpretation: "View interpretation",
      ContactSupport: "Contact support",
      ViewInstructions: "View instructions",
    },
  },
  LabResultEventsJourneys: {
    PartialResults:
      "Once all results are available, you'll be able to create an interpretation of results and share it with your {{patientTerm}}. We'll notify you when all results are available. ",
    PartialResultsInTheMeanTime: "In the meantime, you can download the partial results.",
    AmendedResults:
      "View amended results and edit the {{-patientTermPossessivePlural}} interpretation of results if required.",
    ResultsReady: "View the test results to interpret them and send to your {{patientTerm}}.",
    ResultsReadyButNotInterpretation: {
      checkInAFewMinutes: "a few minutes",
      checkInTwentyFourHours: "24 hours",
      paragraph1:
        "An interpretation of results is being generated. We'll notify you when the interpretation is available. This usually takes a few minutes.",
      paragraph2: "In the meantime, you can download or share the results.",
    },
  },
  LabResultEventsPrac: {
    NotPurchased: "Test not purchased",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NotPurchased_plural: "Tests not purchased",
    Purchased: "Test purchased",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Purchased_plural: "Tests purchased",
    ShippedToPatient: "Test shipped to {{patientTerm}}",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShippedToPatient_plural: "Tests shipped to {{patientTerm}}",
    DeliveredToPatient: "Test delivered to {{patientTerm}}",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DeliveredToPatient_plural: "Tests delivered to {{patientTerm}}",
    ProcessingAtLab: "Test is being processed at lab",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ProcessingAtLab_plural: "Tests are being processed at lab",
    PartialResults: "Partial results available",
    PartialResultsShared: "Partial results shared with {{patientTerm}}",
    ResultsAvailable: "Results available",
    ResultsShared: "Results shared with {{patientTerm}}",
    Upcoming: "Upcoming",
    InterpretationShared: "Results {{automaticallyOrNot}}shared with {{patientTerm}}",
    Automatically: "automatically",
    Complete: "Results shared with {{patientTerm}}",
    ResultsAmended: "Amended results available",
    ResultsAmendedShared: "Amended results shared with {{patientTerm}}",
    AmendedResults: "View amended results and share them with your {{patientTerm}}.",
    Rejected: {
      title: "Test rejected by authorization network",
      body: "The test was rejected. Your {{patientTerm}} will receive a full refund. Please contact Fullscript support if you have any questions.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Rejected_plural: {
      title: "Tests rejected by authorization network",
      body: "The tests were rejected. Your {{patientTerm}} will receive a full refund. Please contact Fullscript support if you have any questions.",
    },
    Expired: { title: "Test expired", body: "This test expired on {{date}}." },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Expired_plural: { title: "Tests expired", body: "These tests expired on {{date}}." },
    Incomplete: {
      title: "Test incomplete",
      body: "The test couldn't be processed due to a specimen issue. Please contact Fullscript support for next steps.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Incomplete_plural: {
      title: "Tests incomplete",
      body: "The tests couldn't be processed due to a specimen issue. Please contact Fullscript support for next steps.",
    },
    Refunded: {
      title: "Test refunded",
      body: "The {{patientTerm}} was refunded for this test on {{date}}.",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Refunded_plural: {
      title: "Tests refunded",
      body: "The {{patientTerm}} was refunded for these tests on {{date}}. ",
    },
    Canceled: { title: "Test canceled", body: "This test was canceled on {{date}}." },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Canceled_plural: {
      title: "Tests canceled",
      body: "These tests were canceled on {{date}}.",
    },
    SpecimenIssue: "Specimen issue",
    PurchasedInOffice: {
      title: "Test purchased in office",
      body: "The requisition form must be included in the kit shipped back to the lab.",
    },
    Buttons: {
      SendReminder: "Send reminder",
      ViewResults: "View results",
      InterpretResults: "Interpret results",
      ViewInterpretation: "View interpretation",
      DownloadOriginalReport: "Download original report",
      DownloadResults: "Download results",
      ContactSupport: "Contact support",
      ShareWithPatient: "Share results with {{patientTerm}}",
      SharePartialWithPatient: "Share partial results with your {{patientTerm}}",
    },
    ShareResultWithPatientModal: {
      shareWithPatient: "Share results with {{patientTerm}}",
      sharePartialWithPatient: "Share partial results with your {{patientTerm}}",
      content: "Your {{patientTerm}} will be notified that results are available to view.",
      addNotes: "Add notes (optional)",
      shareResults: "Share results",
      resultsShared: "Results shared",
    },
  },
  KeepTrackLabResult: "Keep track of your lab and results on the <1>labs page.</1>",
  Purchased: "Purchased",
  BookYourBloodDrawAppointment: "Book your sample collection appointment",
  FindYourNearestCompanyLocation:
    "Find your nearest Quest location to book a sample collection appointment. Quest locations also typically accept walk-ins without appointments.",
  ResultNotes: "Result notes",
  Or: "or",
  Days: "days",
  Day: "day",
  EmptyState: "No labs sent",
  NoLabOrdersSent: "No lab orders sent",
  NoResultsFound: "No results found",
  OrderLabTestsNow: "Order lab tests to understand your patients' health.",
  ClearSearchLabOrders: "Clear your search or try adjusting the filters applied.",
  LabUnavailableBanner: {
    content:
      "Labs are not available for {{planTypeTerm}}. Change to an individual plan to add labs.",
    button: "Change to individual plan",
  },
  DuplicateBiomarkerBanner: {
    title: "Some tests have duplicate biomarkers:",
    message: "No action is required. You can choose to keep all tests in this plan if you want.",
  },
  LabsCard: {
    title: "Introducing labs",
    content:
      "Order blood tests and specialty kits for your {{patientTermPlural}} through Fullscript.",
    button: "View labs",
    callButton:
      "<bookACallLink>Book a call</bookACallLink> with a support specialist to learn more.",
  },
  trackPackage: "Track package",
  readMore: "Read more",
  readLess: "Read less",
  Journey: "Journey",
  Journeys: "Journeys",
  JourneyDetails: "Journey details",
  TrackYourPatientsHealthBanner:
    "Track your {{patientTermPlural}}' health over time with recurring full-body testing.",
  TrackYourPatientsHealth:
    "Track your {{patientTermPlural}}' health over time with recurring full-body testing. Generate an interpretation of results in minutes. Provide supplement recommendations based on results.",
  ModalityRestrictionTitle: "Can't access test",
  ModalityRestrictionDescription:
    "Your modality and/or State prevents you from seeing this lab test",
  BiomarkersTested: "Biomarkers tested",
  TestUpToBiomarkers: "Test up to 116 biomarkers every 3-12 months.",
  GenerateInterpretation: "Generate an interpretation of results in minutes.",
  ProvideSupplementRecommendations: "Provide supplement recommendations based on results.",
  MoreDetails: "More details",
  BiomarkerCount: "{{count}} biomarker",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BiomarkerCount_plural: "{{count}} biomarkers",
  SelectGender: "Select gender",
  BuildTest: "Build test before adding to plan",
  ActivatedPlanCannotAddJourney: "Cannot add journeys to an active plan",
  InterpretationFee: "Interpretation fee",
  DefaultFee: "Default fee",
  CustomFeeDescription: "Fee charged by your practitioner",
  TestFrequency: "Test frequency",
  EveryFrequency: "Every {{frequency}}",
  PaymentWillRecur: "Payment will recur every {{frequency}}. Cancel anytime, at no charge.",
  ThisIsHowOftenYourPatientWillReceiveARequisition:
    "This is how often your {{patientTerm}} will receive a requisition and be charged for the testing. They can cancel anytime, at no charge.",
  CompareTheBiomarkersInEachTest: "Compare the biomarkers in each test.",
  ViewBiomarkersIncluded: "View biomarkers included in each test.",
  Compare: "Compare",
  CompareTests: "Compare tests",
  CloseCompareJourneysModal: "Close compare journeys modal",
  Comprehensive: "Comprehensive",
  Essential: "Essential",
  InterpretationOfResults: "Interpretation of results",
  JourneySampleTypes: "Blood and Urine",
  JourneySampleType: "Sample type: Blood and Urine",
  JourneyLocation: "Location: {{journeyLocation}} (phlebotomy required)",
  SomethingAboutBenefits:
    "Our interpretation builder makes it seamless to generate a robust and easy-to-understand interpretation of test results for your patient. It automatically groups test results by health area and creates data visualizations for each biomarker. You can also flag out-of-range results, add notes, and suggest actionable next steps such as lifestyle and supplement recommendations. You have full control to edit each area of the interpretation, and share it with your {{patientTerm}} when you're ready.",
  ThisIsFullBodyLabTesting:
    "This is full-body lab testing done on a regular basis. It provides a complete picture of your {{patientTerm}}'s overall health, including cardiovascular, metabolic, kidney, nutrient, hormone, and blood health levels.",
  EachTimeResultsAreReady:
    "Each time test results are ready, you can seamlessly generate an interpretation of results to share with your {{patientTerm}}. The interpretation includes data visualizations of test results, performance of each biomarker over time, and lifestyle and supplement recommendations.",
  ThisTestingIsAnExcellentWay:
    "This testing is an excellent way to proactively identify subtle changes to your {{patientTerm}}'s health over time, before something becomes a larger health issue. Or, this testing can also help pinpoint the root cause of an existing symptom. Either way, it's a pathway for your {{patientTerm}} to live a healthier, more balanced life.",
  WhenBuildingTheTest:
    "When building the test, select the frequency that's best for your {{patientTerm}}'s health – every 3, 6, or 12 months. Then, select the test type that's best for your {{patientTerm}}'s health – the Essential test with 88 biomarkers, or the Comprehensive test with 116 biomarkers.",
  YouAlsoHaveTheOptionToCharge:
    "You also have the option to charge an interpretation fee to your {{patientTerm}} directly through Fullscript, at whatever rate you see fit. The interpretation fee is added to the price the {{patientTerm}} pays each time the test recurs (every 3, 6, or 12 months). This fee typically covers your time to interpret the test results and provide your {{patientTerm}} with a clear summary and actionable feedback.",
  ViewInterpretation: "View interpretation",
  ViewInterpretations: "View interpretations",
  AFastAndThoroughTestInterpretation: "A fast and thorough test interpretation",
  PoweredByAdvancedTechnology: "Powered by advanced technology",
  BuildTestBeforeAddingToPlan: "Build test before adding to plan",
  LabsRegistrationMessage: {
    title: "Practitioner hasn't registered for labs",
    content:
      "{{pracName}} must register for labs before they can recommend them to {{patientTermPlural}}.",
    button: {
      learnMore: "Learn more",
    },
  },
  signingPractitioner: "Signing practitioner",
  authFeePerTest: "Authorization network ({{labsAuthNetworkFee}} per test)",
  LabsRegisterForAuthNetworkBanner: {
    practitioner: {
      title: "Register for the authorization network",
      content:
        "To recommend this test, you need to use the authorization network. The authorization network will cost your {{patientTerm}} {{fee}} per panel or speciality test.",
      contentHasSigningPrac:
        "To recommend this test, you need to use the authorization network. Or, the store owner can assign an authorized practitioner from your dispensary to sign for lab orders.",
      contentIsStoreOwner:
        "To recommend this test, you need to use the authorization network. Or, you can assign an authorized practitioner from your dispensary to sign for lab orders. <1>Contact support</1>",
      registerForNetwork: "Register for network",
      assignSigningPrac: "Assign signing practitioner",
    },
    clerk: {
      title: "Practitioner hasn't registered for the authorization network",
      content:
        "{{practitionerName}} needs to register for the authorization network to recommend this test.",
      contentHasSigningPrac:
        "{{practitionerName}} needs to register for the authorization network to recommend this test. Or, the store owner can assign a licensed practitioner from your dispensary to sign for lab orders.",
    },
    learnMore: "Learn more",
  },
  CompanyFee: "{{name}} fee",
  BloodDrawFee: "Blood draw fee",
  QuestCompanyBloodDrawFeeName: "Quest blood draw",
  LabCompanyFee: "Lab company fee",
  QuestSampleCollectionFee: "Quest sample collection fee",
  ACompanyFeeRequired: "The lab company charges this fee for processing the order.",
  CompanyFeePatientDescription: "This is the fee charged by the lab company to perform the test",
  AFeeAppliesToTheFollowingTests: "A fee of ${{amount}} applies to the following tests: ",
  ALabCompanyFeeAppliesToTestsOfCompanies:
    "A total fee of <blur>${{amount}}</blur> applies to the tests of the following companies: ",
  NextTestOn: "Next test on: {{date}}",
  UpcomingTest: "Upcoming test: {{date}}",
  Manage: "Manage",
  journeyCancelled: "Journey subscription cancelled",
  CancelledByOn: "Cancelled by {{name}} on {{date}}",
  CancelledBy: "Cancelled by {{name}}",
  Upcoming: "Upcoming",
  AllOrders: "All orders",
  NotPurchased: "Not purchased",
  ResultsAvailable: "Results available",
  ActionNeeded: "Action needed",
  LabSigningPractitionerBanner: {
    title: "Assign a signing practitioner for lab orders",
    content:
      "You can assign an authorized practitioner in your dispensary to sign for lab orders. This will save {{patientTermPlural}} from paying an authorization network fee if their practitioner isn't authorized for lab orders.",
    mainAction: "Assign signing practitioner",
    dimiss: "Dismiss",
  },
  filters: {
    practitionerFilters: {
      title: "Practitioner",
      myLabs: "My labs",
      all: "All",
    },
    status: {
      title: "Status",
    },
    sortTitle: "Sort by",
    sortFilters: {
      priority: "Status priority",
      planSentOldest: "Plan sent (oldest)",
      planSentNewest: "Plan sent (newest)",
      recentlyUpdated: "Recently updated",
    },
  },
  Favorites: "Favorites",
  OnlyShowFavorites: "Only show favorites",
  AddedToFavorites: "Added to favorites",
  RemovedFromFavorites: "Removed from favorites",
  CustomFee: "Custom fee",
  Fee: "Fee",
  AddCustomFee: "Add custom fee",
  UpdateCustomFee: "Update custom fee",
  UpdateCustomFeeDropdown: "Update custom fee dropdown",
  Optimal: "Optimal",
  OPTIMAL: "Optimal",
  SUBOPTIMAL: "Suboptimal",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OUT_OF_RANGE: "Out of range",
  UNAVAILABLE: "Unavailable",
  SubOptimal: "Suboptimal",
  SubOptimalHigh: "Suboptimal high",
  SubOptimalLow: "Suboptimal low",
  Low: "Low",
  High: "High",
  OutOfRange: "Out of range",
  Unavailable: "Unavailable",
  AboutYourResults: "About your results",
  FromYourPractitioner: "From {{practitionerName}}",
  FlaggedByYourPractitioner: "Flagged by your practitioner",
  AboutBiomarker: "About {{- biomarkerName}}",
  NoResultsInformationFound: "Biomarker results information not found",
  PatientBiomarkerUnavailableMessage:
    "This result is unavailable. Download the original report from Quest to view more details.",
  PatientBiomarkerDNRMessage:
    'The result for this biomarker is "do not report". Download the original report from Quest to view more details.',
  CustomFeeModal: {
    add: "Add custom fee",
    edit: "Edit custom fee",
    enable: "Enable custom fee",
    content:
      "Your {{patientTerm}} is charged this fee in addition to the cost of the lab test. You receive 95% of the custom fee and Fullscript retains 5%. Fullscript pays out your custom fee once lab test results are available.",
    content2:
      "Add a custom fee to each lab recommendation you send through Fullscript. The {{patientTerm}} is charged this fee in addition to the cost of the lab test.",
    content3:
      "You receive 95% of the custom fee and Fullscript retains 5%. Fullscript pays out your custom fee once lab test results are available.",
    content4:
      "This is the default custom fee for you and staff members, but you have the flexibility to edit or remove it for each individual lab recommendation.",
    RemoveCustomFee: "Remove custom fee",
  },
  IneligibleForLabs: {
    Title: "You are not eligible to use labs",
    Description:
      "Requirements are not met to use labs on Fullscript. Contact <1>Fullscript support</1> for more information.",
    Tooltip: "You are not eligible to use labs",
  },
  PractitionerPay: "I'll pay for {{patientTerm}}'s order",
  PractitionerPayTooltip: "Available for non-recurring lab orders only",
  AddCustomFeeBanner: {
    title: "Add a custom fee",
    subPracTitle: "Ask your store owner to add a custom fee",
    content:
      "You can add a fee to each lab recommendation, to cover the cost of consultations or analyzing lab results.",
    cta: "Add custom fee",
    dismiss: "Dismiss",
  },
  ContinueToCheckout: "Continue to checkout",
  PatientInformationRequired: "{{patientTerm}} information is required for lab orders.",
  PatientInformationAgeChangeRequired:
    "{{patientTerm}} does not have minimum required age to order labs.",
  PractitionerPayCheckout: {
    title: "Pay for {{patientTerm}}'s order",
    subtitle:
      "By paying for your {{patientTerm}}'s order, they won't see pricing information for the tests.",
    patientInformation: "{{patientTerm}} information",
    patientAddress: "{{patientTerm}} address",
    patientAddressBody: "Used for requisition forms and shipping of physical products.",
    dateOfBirth: "Date of birth",
    yourPaymentInformation: "Your payment information",
    continueToPayment: "Continue to payment",
    placeOrder: "Place order",
  },
  OrderDetails: {
    header: {
      title: "Order placed for {{patientName}}",
      content: "{{patientName}} will be notified by email.",
    },
    details: {
      header: "Details",
      content:
        " By paying for your {{patientTerm}}'s order, they won't see pricing information for the tests.",
    },
    patientAddress: "{{patientTerm}} address",
    practitionerBillingAddress: "Practitioner billing address",
  },
  PurchasedByPractitioner: "Purchased by {{practitionerName}}",
  PaidByPractitioner: "Paid by practitioner",
  PricingInfoHidden:
    "Pricing information wasn't shared with your {{patientTerm}} since you paid for the order.",
  IntroducingLabsMenu: {
    title: "Introducing labs",
    subtitle: "Browse 700+ tests",
  },
  RegisterforLabsBanner: {
    title: "A better diagnostics experience for all",
    content1: "Affordable pricing",
    content2:
      "Leading lab partners, including Quest Diagnostics, Dutch, Diagnostic Solutions, Doctor's Data, Genova Diagnostics and 3X4 Genetics",
    content3: "Fast, free shipping for diagnostic kits",
  },
  phlebotomyRequired: "Phlebotomy required",
  becomeLabsAccountCompleteModal: {
    successAnimation: "Success animation",
    welcometoLabs: "Welcome to labs",
    closeModal: "Close modal",
    successful: "Your labs registration was successful.",
    recommendation:
      "You can start recommending labs on Fullscript with no platform fees on our beta version at this time.",
    getStarted: "Get started",
    beta: "Beta",
  },
  GetStartedWithgLabs: {
    title: "Get started with labs",
    content1: "Affordable pricing",
    content2:
      "Leading lab partners, including Quest Diagnostics, Dutch, Diagnostic Solutions, Doctor's Data, Genova Diagnostics and 3X4 Genetics",
    content3: "Fast, free shipping for diagnostic kits",
    bookACall: "Book a call",
    dismiss: "Dismiss banner",
  },
  Payment: "Payment",
  ResultTrend: "Result trend",
  ShowResultTrend: "Show result trend",
  HideResultTrend: "Hide result trend",
  Unknown: "Unknown",
  GeneratedByFullscript: "Generated by Fullscript",
  GeneratedByFullscriptBecause:
    "Generated by Fullscript because biomarker is outside of the optimal range",
  LabVideoCard: {
    title: "Providers Guide to Labs",
    chapters: "Chapters",
    intro: "Intro",
    chapter1: "Chapter 01 - Welcome to labs on Fullscript",
    chapter2: "Chapter 02 - Find the tests you're looking for",
    chapter3: "Chapter 03 - Access labs and supplements on the same platform",
    chapter4: "Chapter 04 - Testing to meet your specific needs",
    chapter5: "Chapter 05 - Flexibility for your practice",
    chapter6: "Chapter 06 - What will your patients see?",
    chapter7: "Chapter 07 - Your lab's order dashboard",
    chapter8: "Chapter 08 - A look ahead",
  },
  NextOrderDate: "Next: {{nextOrderDate}}",
  ReviewSummary: "Review summary",
  ReviewSummaryMessage: "Review summary message",
  TheSummaryMessageForYourPatientHasntBeenUpdated:
    "The summary message for your {{patientTerm}} hasn't been updated since you made changes to the interpretation. Send interpretation without updating the summary message?",
  LabTestsComparison: {
    maxLabTestsComparedTooltip:
      "You can compare up to {{max}} lab tests at a time. Please remove one before selecting this.",
    maxReached: "You've selected {{max}}/{{max}} lab tests.",
    comparisonCard: {
      buttonAddTest: "Add test",
    },
    compareLabTests: "Compare lab tests",
    testInformation: "Test information",
    biomarkers: "Biomarkers",
    compareLabs: "Compare labs",
    clearAll: "Clear all",
    compare: "Compare",
    remove: "Remove {{ testName }}",
  },
  CustomPanelOrTemplateProductCard: {
    baseCopyTemplate: "Template by",
    baseCopyPanel: "Panel by",
    ownerSelf: "you",
    ownerFullscript: "Fullscript",
  },
  CustomPanelsOrTemplatesPage: {
    title: "Templates and panels",
    myPanelsTitle: "My panels",
    myTemplatesTitle: "My templates",
    fullscriptPanelsTitle: "Panels made by Fullscript",
    fullscriptTemplatesTitle: "Templates made by Fullscript",
    fullscriptTemplatesSubtitle: "View all Fullscript templates that contain lab tests.",
    viewAll: "View all",
  },
  TemplateViewPage: {
    template: "Template",
    madeByPrac: "Template made by {{ fullName }}",
    madeByFullscript: "Template by Fullscript",
    updatedAt: "Updated {{ date }}",
    message: "Message",
    tabs: {
      preview: "Preview",
      evidence: "Evidence",
      modifyAccess: "Modify access",
    },
    deleteTemplate: {
      title: "Delete template",
      content: "Are you sure you want to delete this template?",
    },
    authNetworkBanner: {
      title: "Register for the authorization network",
      content:
        "To recommend this template, you need to use the authorization network. Or, the store owner can assign an authorized practitioner from your dispensary to sign for lab orders.",
      registerForNetwork: "Register for network",
      learnMore: "Learn more",
    },
    templateBlockerForOthersBanner: {
      title: "This template isn't available to all practitioners in this dispensary",
      content:
        "Some practitioners in your dispensary won't be able to view this template, as it contains restricted lab tests. Restricted lab tests are only available to specific modalities.",
      learnMore: "Learn more",
      contactSupport: "Contact support",
    },
  },
  LabsPromotions: {
    DoctorsDataPromo: {
      registerForLabs: "Register for labs to claim your free {{testName}} test by Doctor's Data",
      testWillBeAvailableSoon:
        "Your free {{testName}} test will be sent to you soon! You'll receive an email when the test has been shipped.",
    },
  },
  HurricaneHeleneBanners: {
    TestPDPBannerCopy: {
      title: "Delayed - this item may take longer to ship",
      text: "Lab tests by Genova Diagnostics are temporarily unavailable due to disruptions caused by Hurricane Helene. We are working closely with Genova Diagnostics to resolve this issue. Thank you for your understanding. If you have any questions, please reach out to <>labs@fullscript.com</1>.",
    },
  },
  UnlockAccessTo80MoreLabTests: {
    title: "Unlock access to 80 more lab tests",
    description:
      "Some lab companies require license information before you can recommend them. Alternatively, you can recommend them using the authorization network. Add license information or register for the authorization network to access lab tests provided by Doctor's Data, Genova Diagnostics, and Diagnostic Solutions Laboratory.",
  },
  PhlebotomyBooking: {
    ScheduleAppointment: {
      title: "Select a location",
      schedulingPageTitle: "Select a date and time",
      upcomingAppointmentTitle: "Upcoming appointment",
      cta: "Schedule appointment",
      cardSubLabel: "Schedule a sample collection appointment at a Quest location near you.",
      viewTestDetailsCta: "View test details",
      cardTitle: "Schedule an appointment",
      searchInputPlaceholder: "Search by city, state, or zipcode",
      showMoreBtn: "Show more",
      scheduleBtn: "Schedule appointment",
      changeLocationBtn: "Change location",
      hoursTitle: "Hours",
      availableTimesLabel: "Available times",
      dateLabel: "Date",
      miles: "miles",
      noResults: "No results found",
      addToCalendarError: "Unable to generate a calendar invite",
      selectATime: "Select a time",
      closed: "Closed",
      preventBooking: "Same day booking is not allowed for tests that require fasting.",
      selectDate: "Select date",
    },
    ScheduleMobilePhlebAppointment: {
      title: "Schedule an appointment",
      cardSubLabel:
        "Schedule your sample collection appointment with Getlabs. A specialist will come to any location you choose, including your home or office.",
    },
    UpcomingAppointment: {
      title: "Upcoming appointment",
      manageAppointment: "Manage appointment",
    },
    ProcessingAtLab: {
      title: "Processing at lab",
      description:
        "Quest Diagnostics is processing your test. On average, this takes 10 days. You'll receive an email when your practitioner shares your results.",
      missedAppointment:
        "If you missed your appointment, you can <rescheduleLink>reschedule it</rescheduleLink>.",
    },
    PurchasedInOffice: {
      title: "Complete your test",
      description: "Follow the instructions to collect your sample(s).",
    },
    unableToGetGeolocationError: "Unable to get current location",
    unableToUseGeolocationTooltip: "Unable to use current location",
    appointmentInstructions: {
      title: "Prepare for your appointment",
      step1: "Arrive 10 minutes early",
      step2: "Bring a valid photo ID",
      step3: "Have your requisition with you-printed and mobile versions are accepted",
      step4:
        "Follow preparation instructions before your appointment, if your practitioner provided any",
    },
    ReviewAppointment: {
      cancelAppointmentCTA: "Cancel appointment",
      rescheduleAppointmentCTA: "Reschedule appointment",
      addToCalendarCTA: "Add to calendar",
      getDirectionsCTA: "Get directions",
    },
    CancelAppointmentModal: {
      title: "Cancel upcoming appointment?",
      keepAppointment: "Keep appointment",
      cancelAppointment: "Cancel appointment",
      cancelled: "Appointment cancelled",
    },
    AppointmentConfirmationModal: {
      title: "Your appointment is confirmed",
      subtitle: "You'll receive a confirmation email to {{email}}.",
    },
    AppointmentUnavailableBanner: {
      title: "Appointment booking unavailable",
      content: "Please try again in a few minutes, or schedule directly on the Quest website.",
      cta: "Schedule through Quest",
      error: "Appointment booking unavailable",
    },
  },
  LabsRegistrationRequired: "Labs registration required",
  Wholesale: {
    wholesaleLabKits: "Lab kits",
    labKitDetails: {
      aboutTheKit: "About the kit",
      availableTestsWithThisKit: "Available tests with this kit",
      availableAddOnsWithThisKit: "Available add-ons with this kit",
      addOn: "Add-On",
      howLabKitsWork: "How lab kits work",
      orderAndStockKits: "Order and stock kits",
      orderAndStockKitsDescription:
        "Order lab kits at no cost (excluding shipping) and keep them in your office.",
      activateTheKit: "Activate the kit",
      activateTheKitDescription:
        "Activate the kit in Fullscript and checkout before giving it to your patient.",
      patientCollectSamples: "Patient collects samples",
      patientCollectSamplesDescription:
        "Instructions and prepaid shipping materials are included in the box.",
      receiveAndReviewResults: "Receive and review results",
      receiveAndReviewResultsDescription:
        "Access test results in your Fullscript practitioner portal.",
      youCanStock: "You can stock a total of {{maxQuantity}} kits in your office at one time.",
      sinceYourDispensary:
        "Since your dispensary already has {{currentQuantity}} kit in stock or in your cart, you can add up to {{availableQuantity}} more.",
      sinceYourDispensaryPlural:
        "Since your dispensary already has {{currentQuantity}} kits in stock or in your cart, you can add up to {{availableQuantity}} more.",
      testCode: "Test code: {{value}}",
      priceAtKitActivation: "${{price}} at kit activation",
    },
    planReview: {
      checkoutToCompleteLabKitActivation: "Checkout to complete lab kit activation",
      deliveryMethod: "Delivery method",
      shipKitDirectlyToPatient: "Ship kit directly to patient",
      useInventoryInOffice: "Use inventory in office ({{inOfficeStockCount}} in stock)",
      enterActivationCode: "Enter activation code",
      labKit: "Lab kit: {{name}} by {{companyName}}",
      deliveryMethodBanners: {
        someTestsNotInOffice: {
          title: "One or more speciality tests are not stored in-office",
          message:
            "To use in-office inventory, remove speciality tests that are not available in-office. To include all tests in this plan, update the delivery method to 'Ship kit directly to patient' to proceed.",
        },
        allTestsInOffice: {
          title: "All speciality tests must use the same delivery method",
          message:
            "To use in-office inventory, all speciality tests in the plan must have the same delivery method. Update the delivery method for all tests to either 'Use inventory in office' or 'Ship kit directly to patient' to proceed.",
        },
      },
    },
    banner: {
      new: "New",
      title: "Stock lab kits in your office",
      content1: "Stock a total of {{maxQuantity}} lab kits at a time, at no cost.",
      content2: "Recommend a lab kit to your {{patientTerm}} while they're in office.",
      content3: "Activate the kit and capture payment before the {{patientTerm}} leaves.",
      button: "View lab kits",
    },
    reorderLabKitsModal: {
      title: "Reorder lab kits",
      stockMessage:
        "Stock a total of {{maxQuantity}} kits in your office at one time. If you need more than {{maxQuantity}} kits, <supportLink>contact Fullscript support</supportLink> to inquire.",
      existingKitsMessage:
        "You can stock a total of {{maxQuantity}} kits in your office at one time. Since you already have {{kitsInStockOrCart}} kits in stock or in your cart, you can add up to {{availableQuantity}} more.<br/><br/>If you need to stock more than {{maxQuantity}} kits, <supportLink>contact Fullscript support</supportLink> to inquire.",
      availableStock: "<strong>{{availableQuantity}}</strong> available to order",
      currentStock: "<strong>{{currentQuantity}}</strong> stocked in office",
      numSelected: "{{currentQuantity}} selected",
      addToCart: "Add to cart",
      error: {
        title: "Total order can't exceed {{maxQuantity}} lab kits",
        content:
          "You can stock a total of {{maxQuantity}} kits in your office at one time. Since you already have {{kitsInStockOrCart}} kits in stock or in your cart, you can add up to {{availableQuantity}} more.",
      },
    },
    inventoryLimitReachedModal: {
      title: "Inventory limit reached",
      content:
        "You can stock a total of {{maxQuantity}} kits in your office at one time. Since you already have {{kitsInStockOrCart}} kits in stock or in your cart, you can add up to {{availableQuantity}} more. If you need to stock more than {{maxQuantity}} kits, <supportLink>contact Fullscript support</supportLink> to inquire.",
    },
    cantExceedMaxQuantity: "Can't exceed {{maxQuantity}} kits",
    stockInformation: {
      message:
        "Stock a total of {{maxQuantity}} kits in your office at one time. If you need more than {{maxQuantity}} kits, <supportLink>contact Fullscript support</supportLink> to inquire.",
      availableStock: "{{availableQuantity}} available to order",
      currentStock: "{{currentQuantity}} stocked in office",
    },
    kitActivation: {
      activateLabKit: "Activate lab kit",
      continueButton: "Continue",
      addToPlanButton: "Add to plan",
      activationPage: {
        enterCodeMessage: "Enter the activation code on the lab kit box",
        activationCodeLabel: "Activation code",
        message: {
          title: "Where's my activation code?",
          content:
            "There is a sticker on each lab kit box with an activation code. If the sticker is missing or damaged, <supportLink>contact Fullscript support</supportLink>.",
        },
        tooltip: "Enter activation code",
        errors: {
          codeNotFound:
            "Code not found. Edit the code or <supportLink>contact Fullscript support</supportLink>.",
          codeAlreadyUsed:
            "This code was already used. <supportLink>Contact Fullscript support</supportLink> with any questions.",
          kitExpired:
            "Kit expired. <supportLink>Contact Fullscript support</supportLink> with any questions.",
          codeNotAssociatedWithKit: "Code is not associated with this kit.",
          recommendationAlreadyHasActivationCode:
            "This recommendation already has an activation code.",
          default:
            "An error occurred. <supportLink>Contact Fullscript support</supportLink> with any questions.",
        },
      },
      selectTestPage: {
        kitName: "Kit: {{kitName}} by {{kitCompany}}",
        selectTest: "Select test",
        viewDetails: "View details",
        tooltip: "Select test",
      },
      selectAddOnsPage: {
        selectAddOns: "Select add-ons (optional)",
        addOnName: "{{name}}",
        noAddOns: "No add-ons available",
      },
    },
    checkout: {
      labKitSubtotal: "Lab kit subtotal",
      productSubtotal: "Product subtotal",
      availableToOrder: "<strong>{{availableQuantity}}</strong> available to order",
      inventoryLimitReached: "Lab kit inventory limit reached",
    },
    inOfficeLabKitRequisitionModal: {
      title: "View lab requisition",
      content1:
        "Print the requisition for the kit you're handing over to your {{patientTerm}}. If the sample is drawn in-office, include the requisition in the kit box before sending it back to the lab.",
      content2:
        "If the sample will be collected at home, the requisition is also available in your {{patientTerm}}'s account for them to print.",
      viewRequisition: "View requisition",
    },
  },
  MobilePhlebotomyBooking: {
    sampleCollection: "Sample collection",
    enterYourZipCodeToSelectOption: "Enter your zip code to select a sample collection option",
    viewOptions: "View options",
    options: {
      quest: {
        title: "Sample collection at Quest Diagnostics",
        link: "Find nearby locations",
      },
      getLabs: {
        title: "At-home sample collection with Getlabs",
        link: "How does at-home work?",
      },
    },
    selectASampleCollectionOption: "Select a sample collection option",
    invalidZipCodeError: "Enter a valid zip code",
    emptyZipCodeError: "Enter your zip code to select a sample collection option",
    atHomeCollectionNotAvailable: "At-home collection is not available for your location",
    atHomeCollectionModal: {
      title: "At-home sample collection",
      description:
        "Get your sample collection at home or work by a professional phlebotomist. No lab visit required—a trained specialist comes to you, ensuring convenience and comfort.",
      listItem1: "Skip the drive and waiting room",
      listItem2: "Enjoy the process in a familiar environment",
      listItem3: "Get tested without disrupting your day",
      cta: "Done",
    },
    closestQuestLocationOverThreshold:
      "The closest Quest Diagnostics location is over {{distance}} miles away. Consider an at-home sample collection for convenience.",
    schedule: {
      title: "Schedule your appointment with Getlabs",
      inputAddress: {
        title: "Enter address",
        description:
          "A specialist will come to any location you choose, including your home or office",
        unitOptional: "Unit (optional)",
      },
      notServiced:
        "Getlabs doesn't service this address. Enter a different address or <supportLink>contact Fullscript support</supportLink> for assistance.",
      selectDateAndTime: {
        title: "Select date and time",
        selectDate: "Select date",
        date: "Date",
        tooltip: "Enter an address to select a date",
        selectArrivalWindow: "Select arrival window",
        description: "When the specialist arrives, the appointment will last about 10 minutes.",
        selectADateToViewTimes: "Select a date to view available times",
        noAvailableTimes: "No available times. Try selecting a different date.",
      },
      contactInformation: {
        title: "Enter contact information",
        phoneNumber: "Phone number",
        checkboxLabel:
          "By selecting this box, you consent to receive text messages from Getlabs for account notifications, customer care, and limited marketing (if opted in). Text HELP to get help, or STOP to stop receiving messages. When texting us, standard message and data rates may apply. The frequency of messages may vary.",
      },
      additionalInformation: {
        title: "Enter additional information",
        specialRequestsOrInstructions: "Special requests or instructions",
        specialRequestsOrInstructionsPlaceholder: "Gate code, entry instructions, or more",
        parkingNotes: "Parking notes",
        parkingNotesPlaceholder: "Add notes related to parking",
      },
      sidebar: {
        title: "Appointment summary",
        description:
          "Book your mobile draw appointment at your prefer location. Mobile draw is provided by Getlabs.",
        cta: "Book appointment",
        tooltip: "Complete all required fields",
      },
      footer:
        "You can cancel or reschedule your appointment at no charge up until 24 hours before. If it's less than 24 hours until your appointment, your payment is non-refundable.",
    },
    manage: {
      getLabsSampleCollectionAppointment: "Getlabs sample collection appointment",
      cancellationFinePrint:
        "You can cancel or reschedule your appointment at no charge up until 24 hours before. If it's less than 24 hours until your appointment, your payment is non-refundable.",
    },
  },
  journeysComeback: {
    scheduleAppointment: {
      title: "Schedule your blood draw",
      cardSubLabel:
        "Schedule an appointment with our GladdMD mobile phlebotomist. They'll meet you at home or another location that is convenient for you.",
      content:
        "<ExternalLink>Schedule an appointment</ExternalLink> with GladdMD's mobile phlebotomist any time from 7am-2pm Tues-Thurs. The phlebotomist will meet you at your home or another location that is convenient for you.",
    },
    fastingReminder: {
      title: "Fasting reminder",
      content:
        "Remember to fast (no food or drinks besides water) for 8-12 hours prior to your blood draw appointment.",
    },
    intakeForm: {
      title: "Get a Personalized Interpretation from Dr. Gladd",
      content:
        "Fill out this <ExternalLink>Intake Form</ExternalLink> so that Dr. Gladd can personalize your results and better understand your goals & needs. Results will come back in about a week. You'll get an email from Fullscript as soon as your personalized interpretation is ready.",
    },
    referFriend: {
      title: "Refer Friends & Family",
      content:
        "This offer is currently only available for GladdMD patients, but we're accepting new patients on our waitlist. Have someone in mind? <ExternalLink>Refer Friends & Family</ExternalLink> here.",
    },
  },
  enableAutomaticallySharingLabResults: "Enable automatic result sharing for Quest Diagnostics",
  saveTimeAndProvidePatientsWithTimelyHealthInsights:
    "Save time and provide {{patientTermPlural}} with timely insights by automatically sharing test results.",
  enableInSettings: "Enable in settings",
  dismiss: "Dismiss",
  automaticResultSharingEnabled: "Automatic result sharing for Quest Diagnostics is enabled",
  patientsWillReceiveEmails:
    "{{patientTermPluralCapitalized}} will receive emails to view lab results and biomarker insights as soon as they're available. Your store owner turned this setting on.",
  learnMore: "Learn more",
  patientsWillReceiveEmailsStoreOwnerSetting:
    "{{patientTermPluralCapitalized}} will receive emails to view lab results and biomarker insights {{numDays}} after they become available. The {{numDaysSingular}} delay gives you the opportunity to review and edit results before they're sent. Your store owner turned this setting on.",
  shareResultsDelayNumbers: {
    asSoonAsAvailable: "As soon as available",
    delay1Day: "1 day",
    delay2Days: "2 days",
    delay3Days: "3 days",
    delay4Days: "4 days",
    delay5Days: "5 days",
    delay6Days: "6 days",
    delay7Days: "7 days",
  },
  shareResultsDelayNumbersSingular: {
    asSoonAsAvailable: "As soon as available",
    delay1Day: "1 day",
    delay2Days: "2 day",
    delay3Days: "3 day",
    delay4Days: "4 day",
    delay5Days: "5 day",
    delay6Days: "6 day",
    delay7Days: "7 day",
  },
  pricingSummary: "Pricing summary",
  findQuestLocations: "Find Quest Diagnostics locations",
  alerts: {
    shippingDelay:
      "Orders for {{labCompanyName}} test kits may take {{dayRange}} extra days to arrive{{shippingDelayReason}}.",
    resultsDelay:
      "{{labCompanyName}} test results are taking longer than expected. The lab is experiencing delays, and results should be available within {{dayRange}} extra days.",
    defaultLabCompanyName: "Boston Heart",
  },
  sampleCollectionFee: "{{companyName}} sample collection fee",
  patientCanChoose: "The {{patientTerm}} can choose",
  samepleCollectionFeeLabel: "Sample collection fee",
  priceVaries: "price varies",
} as const;

export { labs };
